<template>
  <div id="user-profile">
    <b-row class="mt-2">
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
          <div>
            <basic-search
              :prayer-times-sheet.sync="prayerTimesSheet"
              :prayer-times.sync="prayerTimes"
            />
            <export-prayers
              :prayer-times-sheet="prayerTimesSheet"
            />
          </div>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="8"
      >
        <b-card>
          <table id="customers">
            <thead>
              <tr id="mm">
                <th>Day</th>
                <th>Fajr</th>
                <th>Sunrise</th>
                <th>Dhuhur</th>
                <th>Asr</th>
                <th>Maghrib</th>
                <th>Isha</th>
              </tr>
            </thead>
            <tbody
              v-for="(day, index) in prayerTimes"
              :key="index"
            >
              <tr
                class="gray"
              >
                <td class="day">
                  {{ day.day }}
                </td>
                <td
                  v-for="(k, v) in day.data"
                  :key="v"
                  class="text-center"
                >
                  {{ k.prayer }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BasicSearch from '../../common/components/Prayers/BasicSearch.vue'
import ExportPrayers from '@/common/components/Prayers/ExportPrayers.vue'

export default {
  components: {
    BasicSearch,
    ExportPrayers,
  },
  data() {
    return {
      prayerTimes: [],
      prayerTimesSheet: [],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
.form-control {
    border: 1px solid #d8d6de;
}

#customers {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0px 12px;
}

#customers td {
  border: 1px solid #ebedef;
  padding: 15px;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  border: 1px solid #ddd;
  text-align: left;
  background-color: #00A5AF;
  color: white;
  padding: 12px 26px;
}
</style>

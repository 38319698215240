<template>
  <b-row>
    <!-- City -->
    <b-col cols="12">
      <b-form-group
        label="City"
        label-for="search"
      >
        <b-form-input
          id="city"
          v-model="cityData.city"
          placeholder="City"
        />
      </b-form-group>
    </b-col>

    <!-- Country -->
    <b-col cols="12">
      <b-form-group
        label="Country"
        label-for="country"
      >
        <b-form-input
          id="country"
          v-model="cityData.country"
          placeholder="Country"
        />
      </b-form-group>
    </b-col>

    <!-- From -->
    <b-col cols="12">
      <b-form-group
        label="From"
        label-for="search"
      >
        <b-form-datepicker
          id="from"
          v-model="cityData.from"
          class="mb-1"
        />
      </b-form-group>
    </b-col>

    <!-- To -->
    <b-col cols="12">
      <b-form-group
        label="To"
        label-for="search"
      >
        <b-form-datepicker
          id="to"
          v-model="cityData.to"
          class="mb-1"
        />
      </b-form-group>
    </b-col>

    <!-- Advanced -->
    <b-col cols="12">
      <b-form-group
        label-for="submit"
      >
        <b-button
          variant="flat-primary"
          @click="advanced = !advanced"
        >
          <feather-icon icon="SettingsIcon" /> Advanced Options
        </b-button>
      </b-form-group>
    </b-col>
    <b-row v-if="advanced">

      <!-- Timezone -->
      <b-col cols="12">
        <b-form-group
          label="Timezone"
          label-for="timezone"
        >
          <b-form-input
            id="timezone"
            v-model="cityData.timezone"
            placeholder="Timezone"
          />
        </b-form-group>
      </b-col>

      <!-- Calculation Method -->
      <b-col cols="12">
        <b-form-group
          label="Calculation Method"
          label-for="calcmethod"
        >
          <b-form-select
            id="calcmethod"
            v-model="cityData.calculation_method"
            :options="settings.calculation_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Madhab Method -->
      <b-col cols="12">
        <b-form-group
          label="Madhab Method"
          label-for="madhmethod"
        >
          <b-form-select
            id="madhmethod"
            v-model="cityData.madhab_method"
            :options="settings.madhab_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- High Latitude Rule Methods -->
      <b-col cols="12">
        <b-form-group
          label="High Latitude Rule Methods"
          label-for="HLRM"
        >
          <b-form-select
            id="HLRM"
            v-model="cityData.high_latitude_rule"
            :options="settings.high_latitude_rule_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Polar Circle Resolution Methods -->
      <b-col cols="12">
        <b-form-group
          label="Polar Circle Resolution Methods"
          label-for="PCRM"
        >
          <b-form-select
            id="PCRM"
            v-model="cityData.polar_circle_resolution"
            :options="settings.polar_circle_resolution_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Fajr Angle -->
      <b-col cols="12">
        <b-form-group
          label="Fajr Angle"
          label-for="fajrangel"
        >
          <b-form-select
            id="fajrangel"
            v-model="cityData.fajr_angle"
            :options="settings.fajr_angles"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Isha Angle -->
      <b-col cols="12">
        <b-form-group
          label="Isha Angle"
          label-for="ishaangel"
        >
          <b-form-select
            id="ishaangel"
            v-model="cityData.isha_angle"
            :options="settings.isha_angles"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Adjustments -->
      <b-col cols="12">
        <b-form-group
          label="Adjustments"
          label-for="adjustments"
        >
          <b-form-select
            id="adjustments"
            v-model="cityData.adjustments"
            :options="settings.adjustment"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Lat -->
      <b-col cols="12">
        <b-form-group
          label="Lat"
          label-for="lat"
        >
          <b-form-input
            id="lat"
            v-model="cityData.lat"
            placeholder="Lat"
            type="number"
          />
        </b-form-group>
      </b-col>

      <!-- Long -->
      <b-col cols="12">
        <b-form-group
          label="Long"
          label-for="long"
        >
          <b-form-input
            id="long"
            v-model="cityData.long"
            placeholder="Long"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Search-BTN -->
    <b-col cols="12">
      <b-form-group
        label-for="submit"
      >
        <b-button
          variant="primary"
          @click="getPrayersByCity()"
        >
          Search
        </b-button>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>

export default {
  data() {
    return {
      advanced: false,
      settings: [],
      cityData: {
        city: '',
        country: '',
        from: '',
        to: '',
        timezone: '',
        calculation_method: '',
        madhab_method: '',
        high_latitude_rule: '',
        polar_circle_resolution: '',
        fajr_angle: '',
        isha_angle: '',
        adjustments: '',
        lat: '',
        long: '',
      },
    }
  },
  created() {
    this.getSetting()
  },
  methods: {
    getPrayersByCity() {
      this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/prayers-by-city`, this.cityData).then(res => {
        const { prayers } = res.data.data
        this.$emit('update:prayerTimes', prayers)
        const excelSheet = this.formatExcelSheet(prayers)
        this.$emit('update:prayerTimesSheet', excelSheet)
      })
    },
    formatExcelSheet(prayers) {
      const sheet = [{ name: 'azan', data: [] }]
      const sheetData = []

      prayers.forEach(dayPrayers => {
        sheetData.push({
          day: dayPrayers.day,
          'Azan Fajr': dayPrayers.data[0].prayer,
          'Eqama Fajr': '06:00 AM',
          Sunrise: dayPrayers.data[1].prayer,
          'Azan Dohr': dayPrayers.data[2].prayer,
          'Eqama Dohr': '06:00 AM',
          'Azan Asr': dayPrayers.data[3].prayer,
          'Eqama Asr': '06:00 AM',
          'Azan Maghrab': dayPrayers.data[4].prayer,
          'Eqama Maghrab': '06:00 AM',
          'Azan Esha': dayPrayers.data[5].prayer,
          'Eqama Esha': '06:00 AM',
        })
      })
      sheet[0].data = sheetData
      return sheet
    },
    getSetting() {
      this.$http.get(`${process.env.VUE_APP_PRAYERS_BASE_URL}/settings`).then(res => {
        this.settings = res.data.data
      })
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <xlsx-workbook>
      <xlsx-sheet
        v-for="sheet in prayerTimesSheet"
        :key="sheet.name"
        :collection="sheet.data"
        :sheet-name="sheet.name"
      />
      <xlsx-download filename="salwat.xlsx">
        <b-button
          :disabled="prayerTimesSheet.length <= 0"
          variant="primary"
        >
          Export
        </b-button>
      </xlsx-download>
    </xlsx-workbook>
  </div>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx/dist/vue-xlsx.es'

export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    prayerTimesSheet: { type: Array, default: () => [] },
  },
}
</script>
